import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 11",
  week: "Semana 2",
  month: "Mar",
  day: "24",
  monthNumber: "03",
  date: "2020-03-24",
  path: "/cronologia/semana-02#dia-25-mar",
};

const Day11 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 6.584 nuevos positivos y 514 personas fallecidas.
          Permanecen hospitalizadas 22.812 personas, 4.411 más que el día
          anterior, y 584 reciben el alta. El número de casos ingresados en la
          UCI asciende a 2.636, lo que supone un aumento de 281 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 6,8 % y la tasa de recuperados del 9,3 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/24_mar_sanitarios.svg"
          alt="alto número de profesionles sanitarios infectados por coronavirus"
          small={false}
        >
          El número de <strong>profesionales sanitarios infectados</strong> por
          coronavirus COVID-19 en España no deja de crecer diariamente. El
          Sistema Nacional de Salud (SNS) ya tiene{" "}
          <strong>un total de 5.400 contagiados, un 40% más</strong> que el día
          anterior.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/24_mar_test.svg"
          alt="test de coronavirus"
          small={true}
          inverted={true}
        >
          El <strong>Ministerio de sanidad</strong> distribuye los{" "}
          <strong>test rápidos</strong> que permiten detectar en 10-15 minutos
          los casos positivos de COVID19, y servirán para liberar profesionales
          y recursos sanitarios.
        </ModTwoCols>

        <ModTwoCols
          src="/images/svg/24_mar_app.svg"
          alt="nueva app coronamadrid"
          small={false}
        >
          La Comunidad de Madrid lanza hoy la ‘App’ sobre el coronavirus para la
          autoevaluación de los ciudadanos, que facilitará una auto-evaluación a
          los ciudadanos y sus posibles dolencias desde sus casas a través del
          teléfono móvil: Coronamadrid. La aplicación se suma a la{" "}
          <InlineLink link="http://www.coronamadrid.com/">web</InlineLink> web
          que sacaron hace unos días.
        </ModTwoCols>
        <ModText>
          El <strong>Ministerio de Justicia</strong> ha reforzado al colectivo
          de jueces, fiscales, letrados de la Administración de Justicia y otros
          cuerpos del territorio con 4.000 equipos de teletrabajo, para asegurar
          la continuidad de su actividad durante la fase de contención de la
          pandemia del coronavirus.
        </ModText>
        <ModText>
          El <strong>Ministerio de Agricultura, Pesca y Alimentación</strong>{" "}
          resuelve dudas sobre las medidas del Estado de alarma para estos
          sectores, tales como el desplazamiento de trabajadores en vehículos o
          la suspensión de plazos en los procedimientos administrativos
          vinculados a la Política Agrícola Común (PAC).
        </ModText>
        <ModText>
          <strong>
            Se permiten los vuelos entre España e Italia para la repatriación
          </strong>{" "}
          de ciudadanos españoles que se encuentran en ese país.
        </ModText>
        <ModTwoCols
          src="/images/svg/24_mar_consejo_ministros.svg"
          alt="consejo de ministros"
          small={false}
        >
          El Consejo de Ministros aprueba la distribución de un fondo social
          extraordinario entre las comunidades y ciudades autónomas, con 300
          millones para políticas sociales, 25 millones para alimentación
          infantil y 20.000 millones en avales para empresas, pymes y autónomos.
        </ModTwoCols>
        <ModReferenceList title="Guías y docuemntos publicados">
          <ReferenceRow
            link="http://www.coronamadrid.com/"
            name="Portal digital Coronamadrid"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/agricultura/Paginas/2020/240320-covid-info.aspx"
            name="Recomendaciones Ministerio de Agricultura, Pesca y Alimentación"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day11;
